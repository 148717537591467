import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { TextCenter } from 'react-bootstrap-icons';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './ExampleCarouselImage';
import './css/Home.css';
import BackgroundCarousel from './BackgroundCarousel';



const Home = () => {
  const [response, setResponse] = useState(null);
  const textareaRef = useRef(null);

  return (
    <div className='App'>
      <BackgroundCarousel />
      <Container className='content'>
          <header className="my-4" style={{color:"white", textShadow:"-6px 5px 13px black"}}>
            <br />
            <h1 className="text-center">Bem-vindo à Entrega Aki</h1>
            <br />
            <h2 className="text-center">Compre Verduras, Legumes e Frutas com benefícios sem sair de casa!</h2>
            <br />
          </header>
        
          <Row className="mb-4">
            <Col>
              <Card className="text-center">
                <Card.Body style={{backgroundColor:"#ffffb1"}}> 
                  <Card.Title>Verduras Frescas</Card.Title>
                  <Card.Text>
                    Você compra no site e entregamos terças, quintas e sábados
                  </Card.Text>
                  <Button variant="success">Comprar Verduras</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
                <Card.Body style={{backgroundColor:"#ffffb1"}}>
                  <Card.Title>Economia</Card.Title>
                  <Card.Text>
                    Com uma melhor logistica evitamos desperdicio dos produtos pereciveis trazendo economia ao cliente.
                  </Card.Text>
                  <Button variant="success">Comprar Legumes</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="text-center">
              <Card.Body style={{backgroundColor:"#ffffb1"}}>
                  <Card.Title>Variaedade de opções</Card.Title>
                  <Card.Text>
                    Os alimentos mais saudáveis para sua mesa, diretamente do produtor.
                  </Card.Text>
                  <Button variant="success">Comprar Frutas</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>




          {/* <Carousel className="mb-4">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="../imgs/home3.png"
                alt="Primeiro Produto"
              />
              <Carousel.Caption>
      
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="../imgs/home4.png"
                alt="Segundo Produto"
              />
              <Carousel.Caption>
      
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="../imgs/home5.png"
                alt="Terceiro Produto"
              />
              <Carousel.Caption>

              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>   */}
          
      </Container>
    </div>
  );
};

export default Home;