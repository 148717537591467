import React, { useState } from 'react';
import Papa from 'papaparse';
import "./css/ProdutosImportar.css"
import { Button } from 'react-bootstrap';
import axios from 'axios';

function ProdutosImportar() {
  const [products, setProducts] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL : apiUrl
  });

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        setProducts(result.data);
        console.log(result.data);
        e.target.value = null;

      },
    });
  };

  const handleAplicarPreco = async () => {

    if(!selectedDate){
      alert('Selecione a Data Limite da disponibilidade dos produtos.');
      return
    }

    const data = {
      products : products,
      dataLimite : selectedDate
    }


    // await fetch("/aplicar-preco", {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${localStorage.getItem('token')}`
    //   },
    //   query: JSON.stringify(data)
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log('Resposta:', data);
    //   })
    //   .catch(error => {
    //     console.error('Erro:', error);
    //   });

    // formData,{params: formData}

    await api.post("/aplicar-preco",  data,{params: data, headers: { authorization: `Bearer ${localStorage.getItem('token')}`}} ).then(response => {
      console.log("response aplicar preço");
      console.log(response.data);
      
      alert('Produtos adicionados: ' + response.data)
      // window.location.href = '/Produtos';
      // if(response.data.insertId > 0){
      //   setFormData({ ...formData, mostrarLogin: true })
      //   setFormStatus({className:"alert alert-success", message:'Usuário cadastrado com sucesso! Faça Login para continuar...'})

      // }
    }).catch(err => {
      console.log(err)
      if(err.response.data){
        alert(err.response.data);
      }
    })


    // await api.post("/aplicar-preco",JSON.stringify(data),{headers: { authorization: `Bearer ${localStorage.getItem('token')}`}}  ).then(response => {

    //   console.log("response aplicar preço");
    //   console.log(response.data);
      
    //   alert('Produtos adicionados: ' + response.data)
    //   // window.location.href = '/Produtos';
    //   // if(response.data.insertId > 0){
    //   //   setFormData({ ...formData, mostrarLogin: true })
    //   //   setFormStatus({className:"alert alert-success", message:'Usuário cadastrado com sucesso! Faça Login para continuar...'})

    //   // }
    // }).catch(err => {

    //   console.log(err)

    // })
  }

  return (
    <div>
      <div className="table-container">
        <div className="row">
          <div className="col-md-6">  
          
           <input type="file" onChange={handleFileChange} />
          </div>
          <div className="col-md-6">
            <label htmlFor="date-input" className="form-label">Data Limite:</label>
            <input
              type="date"
              className="form-control"
              id="date-input"
              value={selectedDate}
              onChange={handleDateChange}
              style={{maxWidth:"200px"}}
            />
          </div>          
        </div>

      <br />
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Qtd Estoque</th>
            <th>% CashBack</th>
            <th>Category</th>
            <th>Codigo</th>
            <th>Inativo</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={index}>
              <td>{product.Name}</td>
              <td>{product.Price}</td>
              <td>{product.QtdEstoque}</td>
              <td>{product.CashbackPercentual}</td>
              <td>{product.Category}</td>
              <td>{product.Codigo}</td>
              <td>{product.Inativo}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <Button className='btn-padrao' variant="default" onClick={handleAplicarPreco}>
         Aplicar Preço
      </Button>
      <Button style={{float:'right'}} className='btn-default' variant="default" onClick={ () => {setProducts([])}}>
         Limpar
      </Button>      
      </div>
    </div>
  );
}

export default ProdutosImportar;
